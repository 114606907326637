var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-tab", {
          staticClass: "subtab-card-auto",
          attrs: {
            type: "tabcard",
            tabItems: _vm.workResultEquips,
            inlineLabel: true,
            isTabTitle: true,
            tabTitle: "설비별 고장정보",
            align: "left",
          },
          on: {
            "update:tabItems": function ($event) {
              _vm.workResultEquips = $event
            },
            "update:tab-items": function ($event) {
              _vm.workResultEquips = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    key: tab.key,
                    tag: "component",
                    attrs: {
                      param: tab.param,
                      disabledAppr: _vm.apprDisabled,
                      disabledObj: _vm.disabledObj,
                    },
                    on: {
                      "update:disabledObj": function ($event) {
                        _vm.disabledObj = $event
                      },
                      "update:disabled-obj": function ($event) {
                        _vm.disabledObj = $event
                      },
                      closePopupBreak: _vm.closePopupBreak,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }