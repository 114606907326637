<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="workResultEquips"
        :inlineLabel="true"
        v-model="tab"
        :isTabTitle="true"
        tabTitle="설비별 고장정보"
        align="left"
        class="subtab-card-auto"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param="tab.param"
            :key="tab.key"
            :disabledAppr="apprDisabled"
            :disabledObj.sync="disabledObj"
            @closePopupBreak="closePopupBreak"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
// import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-work',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        equipmentCd: '',
        workResultId: '',
      }),
    },
    workResultEquips: {
      type: Array,
      default: () => [],
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'tab0',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.disabledObj.disabled
    },
    apprDisabled() {
      return this.disabledAppr;
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    closePopupBreak() {
      this.$emit('closePopup')
    },
  }
};
</script>